/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import { ThankYouCard } from '../../components/thankYou';

import style from '../styles/thankYou.module.scss';

const PATRON = 'patrons';

const ThankYouPage = () => {
  const [selectedType, setSelectedType] = useState('');
  let thankYouMsg = 'Thank you for sharing your details';
  // let title = 'Request an invite';

  if (selectedType === 'brands') {
    thankYouMsg = `Thank you for sharing your brand's details`;
    // title = 'Get Funded';
  }

  useEffect(() => {
    const hashValue = window && window.location
      && window.location.hash && window.location.hash.slice(1);
    setSelectedType(hashValue);
  }, []);

  return (
    <Layout>
      <SEO />
      {selectedType === PATRON && (
        <Helmet>
          <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js" />
        </Helmet>
      )}
      <div className={style.contactBanner}>
        <div className={style.contactBannerContent}>
          {/* <div className={style.contactSectionContent}>
            <div className={style.contactBannerHeader}>
              {title}
            </div>
            <div className={style.contactBannerDescription}>
              Fill out the below form and we’ll get back to you as soon as possible.
            </div>
          </div> */}
        </div>
      </div>
      <div className={style.thankYouWrapper}>
        {selectedType === PATRON ? (
          <div className={`${style.thankYouCard} ${style.thankYouCardPatron}`}>
            <div className={`calendly-inline-widget ${style.thankYouCardContainer}`} data-url="https://calendly.com/adwateklub/30min" />
          </div>
        ) : (
          <div className={`${style.thankYouCard}`}>
            <ThankYouCard thankYouMessage={thankYouMsg} selectedType={selectedType} />
          </div>
        )}
      </div>

    </Layout>
  );
};

export default ThankYouPage;
