import React from 'react';
import { Link } from 'gatsby';

import styles from './thankYouCard.module.scss';
import thankYouImage from '../../../../static/thankYou/thankyou.svg';

const ThankYouCard = ({ thankYouMessage, selectedType }) => (
  <div className={styles.cardWrapper}>
    <div className={styles.thankYouMessage}>
      {thankYouMessage}
      {/* Thank you for sharing your details */}
    </div>
    <div className={styles.content}>
      We will reach out to you within the next 24 - 48 hours

      {
        (selectedType && selectedType === 'brands') ? (
          <div className={styles.description}>
            Learn more about Klub’s innovative financing products
            {' '}
            <span>
              <Link to="/brands/#klub-advantage" target="_blank">
                here
              </Link>
            </span>
          </div>
        ) : null
      }
    </div>
    <img src={thankYouImage} alt="" className={styles.thankYouImage} />
  </div>
);

export default ThankYouCard;
